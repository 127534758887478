@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
.fallback-spinner{position:relative;display:flex;height:100vh;width:100%}.loading{position:absolute;left:calc(50% - 35px);top:50%;width:55px;height:55px;border-radius:50%;box-sizing:border-box;border:3px solid transparent}.loading .effect-1,.loading .effect-2{position:absolute;width:100%;height:100%;border:3px solid transparent;border-left:3px solid #2962ff;border-radius:50%;box-sizing:border-box}.loading .effect-1{-webkit-animation:rotate 1s ease infinite;animation:rotate 1s ease infinite}.loading .effect-2{-webkit-animation:rotateOpacity 1s ease infinite 0.1s;animation:rotateOpacity 1s ease infinite 0.1s}.loading .effect-3{width:100%;height:100%;border:3px solid transparent;border-left:3px solid #2962ff;-webkit-animation:rotateOpacity 1s ease infinite 0.2s;animation:rotateOpacity 1s ease infinite 0.2s;border-radius:50%;box-sizing:border-box}.loading .loading .effects{transition:all 0.3s ease}.fallback-logo{position:absolute;left:calc(50% - 45px);top:40%}@-webkit-keyframes rotate{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes rotate{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@-webkit-keyframes rotateOpacity{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);opacity:0.1}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn);opacity:1}}@keyframes rotateOpacity{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);opacity:0.1}100%{-webkit-transform:rotate(1turn);transform:rotate(1turn);opacity:1}}

@font-face {
  font-family: HelveticaBold;
  src: url(/static/media/Helvetica-Bold.ed57aca7.ttf);
}

@font-face {
  font-family: HelveticaReg;
  src: url(/static/media/Helvetica.06113bf7.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: HelveticaReg;
}

*:focus {
  outline: none;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: #9a9a9a;
  border: none;
}

.bodyClone {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
}

main.cont {
  max-width: 1110px;
  margin: 0 auto;
  padding: 0 1rem;
}

a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

button,
a {
  cursor: pointer;
}

.mr-a {
  margin-right: auto;
}

.note {
  padding: 0.8rem;
  margin: 0.5rem 0;
  background: #d8f2ee;
}

.note a.noteLink {
  font-weight: 400;
  color: #00ab92;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  font-family: HelveticaBold, sans-serif;
  background: #00ab92;
  z-index: 90;
}

header .cont {
  padding: 0.5rem;
  height: 100%;
  display: flex;
  align-items: center;
}

header .cont > ul {
  grid-gap: 1rem;
  gap: 1rem;
}

header .cont > ul > li h3,
header .cont > ul > li button {
  font-size: 1rem;
}

header .cont > ul > li span,
header .cont > ul > li strong {
  font-size: 0.8rem;
}

header .logo {
  z-index: 20;
}

header .icon-img {
  height: 1rem;
  opacity: 0.7;
}

header .icon-img-bel {
  height: 1.3rem;
  opacity: 0.7;
}

header .icon-img-bel-mob {
  display: none;
}

header ul.realMenu {
  padding-right: 0;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  list-style: none;
  color: white;
}

header ul.realMenu > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

header ul.realMenu strong {
  margin-left: 1rem;
}

header ul.realMenu > li:nth-child(1) {
  margin-left: 1rem;
}

header ul.realMenu button {
  white-space: nowrap;
  padding: 0.5rem 1rem;
  background: white;
  font-size: 1rem;
  font-family: HelveticaBold;
  color: #00ab92;
  border: none;
  border-radius: 10px;
  opacity: 0.5;
}

header ul.realMenu a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header ul.realMenu a img {
  margin-right: 0.8rem;
}

header ul.fakeMenu {
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.5);
  position: fixed;
  background: white;
  padding: 3rem 1rem 1rem;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.5s ease;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

header ul.fakeMenu > li {
  padding: 0.5rem 0;
  text-align: center;
  width: 100%;
  max-width: 40rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid transparent;
  list-style: none;
  order: 3;
}

header ul.fakeMenu > li:hover {
  border-bottom: 3px solid #a1a1a1;
}

header ul.fakeMenu > li:nth-last-child(1):hover, header ul.fakeMenu > li:nth-last-child(2):hover {
  border-bottom: 3px solid transparent;
}

header ul.fakeMenu > li:nth-child(1) {
  position: relative;
  flex-wrap: wrap;
}

header ul.fakeMenu > li a {
  color: black;
}

header ul.fakeMenu > li li {
  padding: 0.3rem;
  text-align: left;
}

header ul.fakeMenu > li a,
header ul.fakeMenu > li span,
header ul.fakeMenu > li button {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

header ul.fakeMenu > li button {
  font-size: 1.5rem;
  margin: 0 auto;
}

header ul.fakeMenu > li strong {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}

header ul.fakeMenu > li:nth-last-child(1) {
  position: relative;
  order: 1;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
}

header ul.fakeMenu > li:nth-last-child(1) img {
  margin: 0;
}

header ul.fakeMenu > li:nth-last-child(2) {
  order: 2;
}

header ul.fakeMenu button {
  width: 100%;
  border: none;
  background: #00ab92;
  color: white;
  padding: 0.5rem;
}

header ul.fakeMenu .dropList-fake {
  left: auto;
  right: 0;
  width: 100%;
  list-style: none;
}

header ul.fakeMenu .dropList-fake li {
  max-width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #a1a1a1;
  font-weight: 400;
  padding: 1rem;
}

header ul.fakeMenu .dropList-fake.active {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

header ul.fakeMenu .icon-img {
  display: none;
}

header ul.fakeMenu .icon-img-bel {
  display: none;
}

header ul.fakeMenu img[alt="down-arrow"] {
  display: block;
}

header .dropList,
header .dropList-fake {
  list-style: none;
  position: absolute;
  z-index: 999;
  top: 110%;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
  width: auto;
  background: white;
  right: 0;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  transition: 0.2s ease;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  -webkit-transform-origin: top right;
          transform-origin: top right;
}

header .dropList li,
header .dropList-fake li {
  width: 100vw;
  max-width: 10rem;
  padding: 0.3rem;
  border-bottom: 1px solid #000;
}

header .dropList li:nth-child(1),
header .dropList-fake li:nth-child(1) {
  align-items: baseline;
  margin-left: 0;
}

header .dropList.active,
header .dropList-fake.active {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

header img[alt="down-arrow"] {
  margin-left: 10px;
  height: 0.5rem;
}

header li:nth-last-child(1) {
  margin: 0 !important;
  align-items: center;
}

header img[alt="profile"] {
  width: auto;
  height: 2.5rem;
}

header img[alt="logo"] {
  width: auto;
  height: 2.8rem;
}

.burger {
  width: 3rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  z-index: 20;
  display: none;
}

.burger .line {
  height: 7px;
  border-radius: 10px;
  width: 100%;
  background: white;
  transition: 0.3s ease;
}

.burger.active .line {
  background: black;
}

.burger.active .l1 {
  -webkit-transform: translateY(21px) rotate(45deg);
          transform: translateY(21px) rotate(45deg);
}

.burger.active .l2 {
  opacity: 0;
}

.burger.active .l3 {
  -webkit-transform: translateY(-21px) rotate(-45deg);
          transform: translateY(-21px) rotate(-45deg);
}

@media (max-width: 1100px) {
  .mr-a {
    margin: 0;
  }
  header ul.realMenu {
    display: none;
  }
  header ul.fakeMenu {
    display: flex;
  }
  header ul.fakeMenu.active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  header .mr-a {
    margin-right: 0;
  }
  header .icon-img-bel-mob {
    margin-left: auto;
    height: 2rem;
    display: block;
  }
  header .blackBell {
    display: none;
  }
  header .blackBell.active {
    display: block;
  }
  header .whiteBell.active {
    display: none;
  }
  header .burger {
    display: flex;
    margin-left: 1rem;
  }
}

.cnp {
  position: relative;
  flex: 1 1 auto;
  padding: 7rem 0 5rem;
}

.cnp .bg-black {
  position: absolute;
  width: 100%;
  background: #000;
  height: 25rem;
  top: 0;
  left: 0;
}

.cnp .inCont {
  position: relative;
  margin: 0 auto;
  max-width: 690px;
}

.cnp .backText {
  position: absolute;
  margin-bottom: 1rem;
  right: calc(100% + 2rem);
  top: 6px;
  width: 6rem;
  color: #a1a1a1;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: HelveticaBold;
}

.cnp .backText img {
  height: 1.5rem;
  margin-right: 1rem;
}

.cnp-head {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.cnp form {
  background: white;
  padding: 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.322);
}

.cnp .inputLabel {
  font-size: 1.5rem;
  font-weight: 400;
}

.cnp .inputField {
  margin: 0 0 2rem;
}

.cnp .inputField .disclaim {
  margin: 0.5rem 0;
  font-family: HelveticaReg;
  -webkit-transform: scale(0);
          transform: scale(0);
}

.cnp .inputField .disclaim span {
  font-family: HelveticaBold;
}

.cnp .inputField .disclaim.active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.cnp .description {
  position: relative;
}

.cnp .input.desCat {
  padding: 0;
}

.cnp .input.desCat input#catInput {
  border: none;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  padding: 0 1rem;
}

.cnp .desCat_selected {
  -webkit-transform: scale(0);
          transform: scale(0);
  padding: 0 1rem;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  position: absolute;
  z-index: 3;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  top: 1;
  left: 1;
  background: #f5f5f5;
}

.cnp .desCat_selected img {
  height: 1rem;
}

.cnp .desCat_selected.active {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.cnp .desCat_selected p {
  color: #a1a1a1;
}

.cnp .desCat a {
  font-weight: 400;
  color: #00ab92;
}

.cnp .noteOne {
  padding-top: 4rem;
  position: absolute;
  right: 105%;
  top: 27rem;
  top: 0;
  width: 12.6rem;
}

.cnp .noteOne ul {
  width: 100%;
  list-style: none;
  margin-top: 1rem;
}

.cnp .noteOne ul li {
  margin: 0.5rem 0;
  width: 100%;
  display: flex;
  align-items: flex-start;
}

.cnp .noteOne ul li img {
  position: relative;
  top: 3px;
  margin: 0 0.5rem;
  margin-left: 0;
  height: 1rem;
}

.cnp .noteOne ul li p {
  flex: 1 1 auto;
}

.cnp .noteOne img[alt="dragArrow"] {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.cnp .format-inst {
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.cnp .input {
  border: 1px solid #a1a1a1;
  width: 100%;
  height: 2.5rem;
  font-size: 1rem;
  padding: 0 1rem;
  margin: 1rem 0 0;
  position: relative;
}

.cnp .input img.searchCat {
  position: absolute;
  right: 1rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 1.5rem;
}

.cnp .ck-placeholder,
.cnp p[data-placeholder="Insert text here..."] {
  height: 15rem;
}

.cnp .cat {
  position: absolute;
  background: white;
  z-index: 9;
  border: 1px solid #a1a1a1;
  height: 39rem;
  overflow: auto;
  top: 100%;
  left: -1px;
  display: none;
  width: calc(100% + 2px);
}

.cnp .cat::-webkit-scrollbar {
  width: 10px;
}

.cnp .cat::-webkit-scrollbar-track {
  padding: 0 3px;
  border-left: 1.5px solid #a1a1a1;
  background: transparent;
}

.cnp .cat::-webkit-scrollbar-thumb {
  background: #a1a1a1;
}

.cnp .cat ul {
  list-style: none;
  padding-top: 0.5rem;
}

.cnp .cat ul li {
  margin: 5px 0;
}

.cnp .cat-block {
  border-bottom: 1px solid #a1a1a1;
  padding: 1rem 0;
}

.cnp .cat-head,
.cnp .cat li {
  padding: 5px 1rem;
  background: white;
  color: #a1a1a1;
}

.cnp .cat-head:hover,
.cnp .cat li:hover {
  color: white;
  background: #a1a1a1;
}

.cnp .cat-head {
  pointer-events: none;
  font-size: 1.5rem;
}

.cnp .cat.active {
  display: block;
}

.cnp .attach-cont {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  padding: 2rem;
  grid-gap: 1rem;
  gap: 1rem;
  border: 1px dashed #a1a1a1;
}

.cnp .attach-cont label {
  display: inline-flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background: #eeeeee;
  border: 2px solid #a1a1a1;
  color: #a1a1a1;
  font-family: HelveticaBold;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-radius: 10px;
  font-size: 1rem;
  white-space: nowrap;
}

.cnp .attach-cont label img {
  height: 1rem;
}

.cnp .attach-cont p {
  flex: 1 1 15rem;
}

.cnp .royaltyFree {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.cnp .royaltyFree img {
  height: 1.5rem;
}

.cnp .royaltyFree span {
  color: #00ab92;
  font-weight: bold;
  white-space: nowrap;
}

.cnp .deliverables {
  margin: 1rem 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(7rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

.cnp .deliverables_single {
  position: relative;
  padding: 1rem;
  border: 1px solid #a1a1a1;
  text-align: center;
}

.cnp .deliverables_single img {
  height: 5rem;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}

.cnp .deliverables_single img[alt="check-circle"] {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  height: 2rem;
  opacity: 0;
}

.cnp .deliverables input:checked + label {
  border: 2px solid #00ab92;
}

.cnp .deliverables input:checked + label img[alt="check-circle"] {
  opacity: 1;
}

.cnp .assocBrands {
  position: relative;
  padding: 1rem 0 1.5rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(12rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;
}

.cnp .assocBrands_single {
  position: relative;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a1a1a1;
}

.cnp .assocBrands_single p {
  position: absolute;
  left: 50%;
  bottom: 2rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-weight: bold;
  color: #bbbbbb;
  white-space: nowrap;
}

.cnp .assocBrands_head {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.cnp .assocBrands_head img {
  height: 1.5rem;
}

.cnp button.createBrandBtn {
  width: 100%;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  background: #00ab92;
  border: none;
  padding: 0.5rem;
  border-radius: 8px;
}

@media (max-width: 1370px) {
  .cnp .noteOne {
    padding-top: 1rem;
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
  }
  .cnp .noteOne ul li {
    margin: 1rem 0;
  }
  .cnp .noteOne ul li img {
    margin: 0 1rem;
  }
  .cnp .noteOne img[alt="dragArrow"] {
    display: none;
  }
}

@media (max-width: 1200px) {
  .cnp .backText {
    position: static;
  }
}

@media (max-width: 1100px) {
  .cnp {
    padding-top: 7rem;
  }
}

@media (max-width: 345px) {
  .cnp form {
    padding: 1.5rem 1rem;
  }
  .cnp .attach-cont {
    padding: 2rem 1rem;
  }
  .cnp .attach-cont label {
    padding: 0.5rem;
    border-radius: 5px;
  }
}

footer {
  background: #f5f5f5;
}

footer .cont {
  height: 10rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

footer img {
  flex: 0 1 20rem;
  object-fit: contain;
  object-position: left;
  height: auto;
  width: 80vw;
  max-width: 15rem;
}

footer p {
  text-align: right;
  flex: 1 1 20rem;
}

@media (max-width: 671px) {
  footer {
    padding: 2rem 0;
  }
  footer img {
    margin: 0 auto;
    object-position: center;
  }
  footer p {
    text-align: center;
  }
}

.loginPage {
  background: #000;
  padding: 0;
  height: 100vh;
  min-height: 647px;
}

.loginPage .loginBody {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(0.7);
          transform: translate(-50%, -50%) scale(0.7);
  height: 918px;
  width: 100%;
  max-width: 1640px;
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 1.2rem;
  flex-wrap: wrap;
}

.loginPage .loginForm {
  position: relative;
  background: white;
  padding: 50px;
  width: 100%;
  z-index: 100;
  max-width: 600px;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease;
}

.loginPage h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 35px;
  text-align: center;
  max-width: 350px;
}

.loginPage .copyright {
  text-align: center;
}

.loginPage .logo {
  max-width: 252px;
  height: auto;
  width: 100%;
  object-fit: contain;
}

.loginPage a {
  color: #00ab92;
  white-space: nowrap;
}

.loginPage form {
  width: 100%;
  margin-bottom: 3rem;
}

.loginPage form p {
  margin-top: 30px;
  text-align: center;
}

.loginPage form p a {
  font-family: HelveticaBold;
}

.loginPage form .label {
  display: flex;
  align-items: center;
}

.loginPage form .label a {
  margin-left: auto;
}

.loginPage form label {
  font-size: 1.2rem;
  font-family: HelveticaBold;
}

.loginPage form input,
.loginPage form button {
  height: 39px;
  padding: 0 19px;
  width: 100%;
  border: 1px solid #a1a1a1;
  font-size: 1.2rem;
}

.loginPage form input::-webkit-input-placeholder,
.loginPage form button::-webkit-input-placeholder {
  color: #a1a1a1;
}

.loginPage form input:-ms-input-placeholder,
.loginPage form button:-ms-input-placeholder {
  color: #a1a1a1;
}

.loginPage form input::-webkit-input-placeholder, .loginPage form button::-webkit-input-placeholder {
  color: #a1a1a1;
}

.loginPage form input:-ms-input-placeholder, .loginPage form button:-ms-input-placeholder {
  color: #a1a1a1;
}

.loginPage form input::placeholder,
.loginPage form button::placeholder {
  color: #a1a1a1;
}

.loginPage form button {
  font-family: HelveticaBold;
  border: none;
  background: #00ab92;
  color: white;
}

.loginPage form input {
  margin: 1rem 0;
}

.loginPage .inputFields {
  margin: 30px 0;
}

.loginPage .bg-img {
  height: 100%;
  top: 0;
  right: 0;
  position: absolute;
  object-fit: cover;
  width: 64%;
}

@media (max-width: 950px) {
  .loginPage {
    padding: 0;
  }
  .loginPage .loginForm {
    max-width: 9999px;
  }
}

@media (max-width: 600px) {
  .loginPage .loginForm {
    padding: 50px 30px;
  }
}

@media (max-width: 450px) {
  .loginPage {
    min-height: 743px;
  }
  .loginPage .loginBody {
    -webkit-transform: translate(-50%, -50%) scale(0.8);
            transform: translate(-50%, -50%) scale(0.8);
  }
  .loginPage .loginForm {
    padding: 50px 20px;
  }
}

@media (max-width: 367px) {
  .loginPage form .label {
    flex-wrap: wrap;
  }
  .loginPage form .label a {
    margin-left: 0;
  }
}

@media (max-width: 300px) {
  .loginPage form .label a {
    margin-left: 0;
    white-space: pre-wrap;
  }
}

.blp {
  flex: 1 1 auto;
  padding-top: calc(76px + 2rem);
  padding-bottom: 2.8rem;
}

.blp .pageHead {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.blp .pageHead h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

.blp .pageHead button {
  border-radius: 0.5rem;
  background: #000;
  color: white;
  padding: 0.5rem 2.5rem;
  border: 2px solid black;
}

.blp .brandsBody h4 {
  font-weight: 700;
  margin-bottom: 2rem;
}

.blp .brandsBody h4 span {
  font-weight: 400;
  color: #a1a1a1;
}

.blp .brandsList {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(12rem, auto))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(12rem, auto));
  grid-gap: 2rem;
  gap: 2rem;
}

.blp .brandsList_single {
  background: #f5f5f5;
  padding: 0.8rem;
}

.blp .brandsList_single h3 {
  font-size: 1rem;
  white-space: nowrap;
}

.blp .brandsList_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.blp .brandsList_head span {
  color: #a1a1a1;
  font-family: HelveticaBold;
}

.blp .brandsList_left {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.blp .brandsList-p {
  font-size: 0.8rem;
  color: #a1a1a1;
  padding: 1rem 0 0.5rem;
}

.blp .brandsList img[alt="brand-icon"] {
  height: 1.2rem;
}

.blp .brandsList img[alt="gear"] {
  height: 1rem;
}

.cnp.createNew-overLay {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding: 3rem 1rem;
  display: none;
  background: rgba(0, 0, 0, 0.3);
}

.cnp.createNew-overLay.active {
  display: flex;
}

.cnp.createNew-overLay main {
  background: white;
  padding: 1.5rem;
  padding-top: 2.5rem;
  width: 100%;
  max-width: 915px;
  margin: auto;
  height: auto;
  position: relative;
}

.cnp.createNew-overLay .inputDiv {
  padding-right: 0;
  display: flex;
}

.cnp.createNew-overLay .inputDiv input[id="brandName"] {
  flex: 1 1 auto;
  height: 100%;
  border: none;
}

.cnp.createNew-overLay .inputDiv .typeSelect {
  position: relative;
  border-left: 1px solid #a1a1a1;
  width: 5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cnp.createNew-overLay .inputDiv .typeSelect img {
  height: 0.5rem;
}

.cnp.createNew-overLay .inputDiv .typeSelect_hover {
  width: 5rem;
  background: #f5f5f5;
  position: absolute;
  list-style: none;
  border: 1px solid #a1a1a1;
  top: 100%;
  z-index: 10;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: scale(0);
          transform: scale(0);
  opacity: 0;
  transition: 0.3s ease;
  right: 0;
}

.cnp.createNew-overLay .inputDiv .typeSelect_hover li {
  padding: 1rem;
}

.cnp.createNew-overLay .inputDiv .typeSelect:hover .typeSelect_hover {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}

.cnp.createNew-overLay .inputDiv .typeSelect img.brandIconImage {
  height: 1.5rem;
}

.cnp.createNew-overLay .cross {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  height: 1.5rem;
}

.cnp.createNew-overLay .cnp-head {
  color: black;
  margin-bottom: 0;
}

.cnp.createNew-overLay .cnp-para {
  margin: 2rem 0;
}

.cnp.createNew-overLay label[for="brandGuide"] {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  height: 3rem;
  width: 100%;
  background: #f5f5f5;
  color: #a1a1a1;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.cnp.createNew-overLay label[for="brandGuide"] img {
  height: 1rem;
}

.cnp.createNew-overLay .required::after {
  content: "*";
  font-size: 2rem;
  color: #00ab92;
}

.cnp.createNew-overLay form {
  padding: 0;
  box-shadow: none;
}

.cnp.createNew-overLay .createBrandBtn {
  width: 100%;
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  background: #00ab92;
  border: none;
  padding: 0.5rem;
  border-radius: 8px;
}

.cnp.createNew-overLay .brandColors {
  width: 100%;
  margin: 0.5rem 0;
}

.cnp.createNew-overLay .brandColors_single.createColor {
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border: 1px dashed #a1a1a1;
}

.cnp.createNew-overLay .brandColors_single.createColor img {
  height: 2rem;
}

.cnp.createNew-overLay .brandColors_single {
  padding: 1rem;
  justify-content: space-between;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  align-items: center;
  margin: 1rem 0;
  width: 100%;
  height: auto;
  display: flex;
  min-height: 5rem;
  border: 1px solid #a1a1a1;
}

.cnp.createNew-overLay .brandColors_single .color {
  height: 3rem;
  width: 3rem;
  background: #000;
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.5);
}

.cnp.createNew-overLay .brandColors_single span {
  color: #a1a1a1;
  margin-right: auto;
}

.cnp.createNew-overLay .brandColors_single button {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: #a1a1a1;
  background: #f5f5f5;
  border: 1.5px solid #a1a1a1;
}

.cnp.createNew-overLay .brandColors_single .white {
  background: white;
}

.cnp.createNew-overLay .brandColors_single .purple {
  background: #8235dc;
}

.cnp.createNew-overLay .attach {
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 3rem;
  gap: 3rem;
  justify-content: flex-start;
}

.cnp.createNew-overLay .attach ul {
  list-style: none;
}

.cnp.createNew-overLay .attach li {
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.cnp.createNew-overLay .attach img {
  height: 1rem;
}

.colorBox {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.colorBox.active {
  display: flex;
}

.colorBox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.32);
}

.colorBox img[alt="cross sign"] {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  height: 2rem;
}

.colorBox_head {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: start;
}

.colorBox_head h2 {
  font-size: 2.5rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.colorBox_head img {
  height: 3.5rem;
}

.colorBox-main {
  position: relative;
  padding: 1.5rem;
  width: 95%;
  height: auto;
  max-width: 40rem;
  background: white;
  border-radius: 0.3rem;
}

.colorBox-box {
  width: 100%;
  height: 25rem;
  background: #a1a1a1;
  margin: 1.5rem 0;
}

.colorBox-btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.colorBox-btns button {
  border: 1px solid #a1a1a1;
  background: #f5f5f5;
  color: #a1a1a1;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.colorBox-btns button:nth-child(2) {
  color: white;
  background: #00ab92;
  border: 1px solid #00ab92;
}

@media (max-width: 528px) {
  .cnp.createNew-overLay .attach {
    grid-gap: 0;
    gap: 0;
  }
}

.plp {
  padding-bottom: 15rem;
}

.plp .pageHead {
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
}

.plp .pageHead ul.filterProjects {
  margin-left: 3rem;
  flex: 1 1 auto;
  display: flex;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 3rem;
  gap: 3rem;
}

.plp .pageHead ul.filterProjects li {
  padding-bottom: 0.5rem;
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

.plp .pageHead ul.filterProjects li.selected {
  color: #00ab92;
  border-bottom: 2px solid #00ab92;
}

.plp .pageHead .inputDiv {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 20rem;
  margin-right: 1rem;
  background: #f5f5f5;
}

.plp .pageHead .inputDiv img {
  height: 1rem;
  margin: 0 0.5rem;
}

.plp .pageHead input {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  border: none;
}

.plp .productsBody_head {
  margin: 0 !important;
}

.plp .productsBodyComplete .productList_single {
  padding: 0;
  margin: 2rem 0;
}

.plp .productList {
  margin: 2rem 0;
}

.plp .productList_single {
  position: relative;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background: #f5f5f5;
  padding: 1rem;
  margin: 1rem 0;
}

.plp .productList_single .head {
  padding: 1rem;
  background: #f5f5f5;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.plp .productList_single .head .inProg {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.plp .productList_single .head .inProg img {
  height: 1rem;
}

.plp .productList_single .smile {
  border: 1px solid #a1a1a1;
  padding: 0.2rem 0.5rem;
}

.plp .productList_single .smile img {
  height: 1.3rem;
}

.plp .productList_single .foot {
  padding-top: 1rem;
  position: static;
  width: 100%;
  bottom: 0;
  left: 0;
  background: white;
  margin: 0 5px 5px;
  min-height: 70px;
  display: -ms-grid;
  display: grid;
  padding: calc(1rem - 5px);
  grid-gap: 1rem;
  gap: 1rem;
  -ms-grid-columns: (minmax(11rem, 1fr))[auto-fill];
      grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
}

.plp .productList_single .foot_single {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.plp .productList_image {
  height: 7rem;
  margin-right: 1rem;
  flex: 0 1 auto;
}

.plp .productList_details {
  flex: 1 1 48rem;
  padding: 1rem 0;
}

.plp .productList_details .person {
  height: 3rem;
}

.plp .productList_details .heading {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
}

.plp .productList_details .inProg {
  position: relative;
  bottom: 0.4rem;
  padding: 0.5rem;
  background: #00ab92;
  color: white;
  display: inline-flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.plp .productList_details .inProg img {
  height: 1.5rem;
}

.moveOverLay {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding: 3rem 1rem;
  background: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
  display: none;
}

.moveOverLay .move-switch {
  position: relative;
  background: white;
  max-width: 50rem;
  max-height: 100vh;
  overflow: scroll;
  height: auto;
  padding: 2rem 3rem;
  display: flex;
  justify-content: space-between;
  grid-gap: 2rem;
  gap: 2rem;
  flex-direction: column;
}

.moveOverLay .head {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.moveOverLay .head img {
  height: 2.7rem;
}

.moveOverLay .head h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

.moveOverLay .body p {
  margin-bottom: 2rem;
  font-size: 1.3rem;
}

.moveOverLay .body p span {
  color: #00ab92;
}

.moveOverLay .body p.note {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  padding: 1rem;
}

.moveOverLay .body p.note span {
  color: black;
  font-weight: bold;
}

.moveOverLay .activeProject {
  background: #f5f5f5;
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.moveOverLay .activeProject label {
  position: relative;
  border-radius: 50%;
  width: 2rem;
  background: white;
  height: 2rem;
  display: -ms-grid;
  display: grid;
  place-items: center;
  border: 2px solid #a1a1a1;
}

.moveOverLay .activeProject label img {
  display: none;
  height: calc(2rem);
}

.moveOverLay .activeProject input:checked + label {
  border: none;
  background: none;
}

.moveOverLay .activeProject input:checked + label img {
  display: block;
}

.moveOverLay .activeProject h3 {
  flex: 1 1 20rem;
}

.moveOverLay .foot {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.moveOverLay .foot button:nth-child(1) {
  margin-right: auto;
}

.moveOverLay .foot button:nth-last-child(1) {
  background: #00ab92;
  border-color: #00ab92;
  color: white;
}

.moveOverLay .foot button {
  border-radius: 0.5rem;
  padding: 0.5rem 1.2rem;
  background: #f5f5f5;
  color: #a1a1a1;
  border: 1px solid #a1a1a1;
  font-size: 1.2rem;
}

.moveOverLay .move-cross {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 1.5rem;
}

.moveOverLay.active {
  display: flex;
}

@media (max-width: 1100px) and (min-width: 995px) {
  .plp .productList_image + .productList_details + button.option .optionMenu {
    left: 0;
    right: auto;
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}

@media (max-width: 937px) {
  .plp button.option .optionMenu {
    left: 0;
    right: auto;
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}

@media (max-width: 533px) {
  .plp .pageHead .inputDiv {
    margin-right: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

@media (max-width: 468px) {
  .plp .pageHead {
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
  }
  .plp .pageHead ul.filterProjects {
    margin: 1rem 0;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 3rem;
    margin-left: 0;
    justify-content: space-between;
  }
  .plp .pageHead ul.filterProjects li {
    padding-bottom: 0.5rem;
    font-weight: bold;
    border-bottom: 2px solid transparent;
  }
  .plp .pageHead ul.filterProjects li.selected {
    color: #00ab92;
    border-bottom: 2px solid #00ab92;
  }
}

.chat {
  padding: 65px 0 0;
}

.chat main.cont {
  width: 100%;
  height: calc(100vh - 225px);
  overflow: auto;
  padding-right: 0;
  padding-left: 0;
}

.chat .chatCont {
  max-width: 980px;
  margin-left: auto;
  height: calc(100vh - 225px);
}

.chat .chatCont main {
  position: relative;
  width: 100%;
}

.chat .people,
.chat .message {
  position: absolute;
  overflow: visible;
  height: calc(100vh - 225px);
}

.chat .people {
  top: 0;
  left: 0;
  z-index: 2;
  background: white;
  width: 20rem;
  padding-top: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
}

.chat .people .backText {
  position: absolute;
  right: 105%;
  top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: start;
}

.chat .people .backText img {
  height: 1.1rem;
}

.chat .people .backText span {
  font-size: 1.2rem;
  color: #a1a1a1;
}

.chat .people .rev {
  background: #000;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 0.3rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  padding: 0.5rem;
}

.chat .people .rev img {
  margin-left: 0.5rem;
  height: 1rem;
  width: auto;
}

.chat .people_body {
  border-left: 1px solid #a1a1a1;
  border-bottom: 1px solid #a1a1a1;
  overflow-y: auto;
}

.chat .people_body_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 0;
}

.chat .people_body_head h2 {
  font-size: 1.3rem;
}

.chat .people_body_head button {
  font-size: 0.8rem;
  display: flex;
  grid-gap: 0.2rem;
  gap: 0.2rem;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 0.35rem;
}

.chat .people img {
  height: 1rem;
}

.chat .people ul {
  list-style: none;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.chat .people ul li {
  padding: 0.5rem 1rem;
  flex: 1 1 9rem;
  border-top: 1px solid #a1a1a1;
  border-bottom: 1px solid #a1a1a1;
}

.chat .people ul li.active {
  color: #00ab92;
  border-bottom: 1px solid #00ab92 !important;
}

.chat .markComp {
  width: calc(100% - 2rem);
  background: #00ab92;
  margin: 1.5rem 1rem;
  border: none;
  font-family: HelveticaBold;
  border-radius: 0.3rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.chat .showChat {
  background: #a1a1a1;
  display: none;
}

.chat .inSider {
  padding: 1rem;
}

.chat .inSider h3 {
  margin: 0 0 1rem;
  font-size: 1.1rem;
}

.chat .inSider_details {
  margin: 1rem 0;
}

.chat .inSider h4 {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.chat .inSider p {
  font-size: 0.9rem;
  color: #a1a1a1;
}

.chat .inSider_brand, .chat .inSider_attach_single {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.chat .inSider_attach {
  margin: 1rem 0;
}

.chat .message {
  border: 1px solid #a1a1a1;
  border-bottom: none;
  padding: 0 0 5rem;
  top: 0;
  display: flex;
  flex-direction: column;
  right: 0;
  width: calc(100% - 20rem);
  background: white;
}

.chat .message p {
  color: #a1a1a1;
}

.chat .message_backButton {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 2rem);
  height: auto;
  padding: 0.5rem 0;
  background: #00ab92;
  margin: 0.5rem 1rem;
  border: none;
  border-radius: 9px;
  color: white;
  display: none;
}

.chat .message_main {
  overflow-y: auto;
}

.chat .message_msgMake {
  position: absolute;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1;
}

.chat .message_msgMake .sender {
  margin-top: 0;
}

.chat .message_msgMake input {
  background: transparent;
  border: none;
  flex: 1 1 auto;
}

.chat .message_msgMake .message_body {
  padding: 0.7rem;
  display: flex;
  border-radius: 0.3rem;
  background: #f5f5f5;
}

.chat .message_msgMake .message_body .icons {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  flex: 0 1 auto;
}

.chat .message_msgMake .message_body .icons img {
  height: 1.3rem;
}

.chat .message_head {
  display: flex;
  margin-bottom: 0.8rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: baseline;
}

.chat .message_single {
  padding: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.5rem 0;
}

.chat .message_body {
  flex: 1 1 19rem;
}

.chat .message_body .msgPara {
  padding: 0.5rem 1rem;
}

.chat .sender {
  margin-top: 2.3rem;
  flex: 0 1 auto;
  display: -ms-grid;
  display: grid;
  height: auto;
  place-items: center;
}

.chat .senderName {
  font-size: 1.3rem;
}

.chat .senderTime {
  font-size: 1rem;
}

.chat .sender img {
  height: 3.5rem;
  margin: 0 1rem;
}

.chat .msgSTUFF {
  width: 80%;
  max-width: 23rem;
}

.chat .msgSTUFF .actImage {
  width: 100%;
}

.chat .msgSTUFF_imgBox {
  width: 100%;
  position: relative;
}

.chat .msgSTUFF .viewbtn {
  position: absolute;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border: none;
  border-radius: 0.2rem;
  color: #a1a1a1;
  display: none;
}

.chat .msgSTUFF .viewbtn img {
  height: 1rem;
}

.chat .msgSTUFF .viewbtnDes {
  background: white;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.chat .msgSTUFF .viewbtnAll {
  top: 1rem;
  right: 1rem;
}

.chat .msgSTUFF_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: none;
}

.chat .msgSTUFF:hover .msgSTUFF_overlay {
  display: block;
}

.chat .msgSTUFF:hover .viewbtn {
  display: flex;
}

.chat .msgSTUFF_fileDetails {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.chat .msgSTUFF_fileTitle {
  background: #d8f2ee;
}

.chat .msgSTUFF_fileAction {
  background: #f5f5f5;
}

.chat .msgSTUFF_fileAction img {
  height: 1.3rem;
}

.chat .msgSTUFF .fileAction {
  display: flex;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

.chat .heSend .message_body .msgPara {
  background: #f5f5f5;
}

.chat .iSend .message_body .msgPara {
  color: black;
  background: #d8f2ee;
}

@media (max-width: 1090px) {
  .chat .people .backText {
    position: static;
    margin-left: 1rem;
  }
}

@media (max-width: 770px) {
  .chat .people {
    width: 100%;
    transition: -webkit-transform 0.5s ease;
    transition: transform 0.5s ease;
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  .chat .people .showChat {
    display: flex;
  }
  .chat .people.active {
    -webkit-transform: translateX(-120%);
            transform: translateX(-120%);
  }
  .chat .message {
    padding: 3rem 0 5rem;
    width: 100%;
  }
  .chat .message_backButton {
    display: block;
  }
}

@media (max-width: 671px) {
  .chat main.cont {
    min-height: calc(100vh - 289px);
  }
  .chat .chatCont {
    min-height: calc(100vh - 289px);
  }
}

@media (max-width: 422px) {
  .chat .sender img {
    margin-left: 0;
  }
  .chat .message_msgMake .sender {
    display: none;
  }
}

.optionMenu {
  position: absolute;
  background: white;
  list-style: none;
  top: 105%;
  right: 0;
  width: auto;
  text-align: left;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: top right;
          transform-origin: top right;
  opacity: 0;
  transition: 0.3s ease;
  z-index: 10;
  box-shadow: inset 0 0 0.3rem rgba(0, 0, 0, 0.171);
}

.optionMenu li {
  margin: 0.5rem 1rem;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
  grid-gap: 1rem;
  gap: 1rem;
  white-space: nowrap;
}

.optionMenu h3 {
  color: black;
  padding: 1rem 1rem 0;
}

.optionMenu li:nth-child(1) {
  color: #00ab92;
}

.optionMenu li:nth-last-child(1) {
  border-top: 1px solid #a1a1a1;
  padding: 1rem;
  margin: 0;
  color: red;
}

.optionMenu img {
  object-fit: contain;
  width: 1.5rem;
}

button.option {
  position: relative;
  border: 1px solid #a1a1a1;
  padding: 0.5rem 0.7rem;
  display: flex;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  font-size: 1rem;
  border-radius: 0.3rem;
  color: #a1a1a1;
}

button.option img {
  height: 1.3rem;
}

button.option:hover .optionMenu {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
/*# sourceMappingURL=styles.css.map */
.loginPage form button:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    background-color: rgb(75, 168, 147);
    border-color: rgb(75, 168, 147);
}

.alert-danger {
    color: #7c2b2a;
    background-color: #fcdddc;
    border-color: #fbcfce;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 2px;
}

.invalid-feedback {
    /* display: none; */
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #ef5350;
}

header ul .dropList li a {
    color: rgb(75, 75, 75);
    text-align: center;
    width: 100%;
}

.headerBrandList:hover {
    cursor: pointer !important;
}

header .dropList li {
    border-bottom: 1px solid rgb(75, 168, 147) !important;
}

header .dropList li:hover {
    background-color: rgb(113, 224, 202);
}

button.option {
    padding: 0.3rem 0.3rem !important;
}

.cross:hover {
    cursor: pointer !important;
}

.ck-editor__editable_inline {
    min-height: 200px;
}

.brandsList-p {
    font-style: italic;
}

.rcp {
    /* width: 100% !important;
    height: 100%; */
    border-radius: 0 !important;
}

.brandsList_left, .filterProjects li:hover {
    cursor: pointer;
}

.disabled-buttons:hover {
    cursor: not-allowed;
}

.deliverables_single:hover, .assocBrands_single:hover, .backText:hover, .pointer:hover, .backText:hover {
    cursor: pointer;
}

.old-attachmennt-cnt {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #a1a1a1;
    margin-bottom: 1rem;
    min-height: 3rem;
    max-height: 7rem;
}

.item {
    display: flex;
    height: 2.5rem;
    background-color: #eeeeee;
    margin: 10px;
    padding: 10px;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    border-radius: 10px;
}

.attachment-img {
    height: 20px;
}

.last {
    margin-left: auto;
}

.attachment-link {
    color: #a1a1a1;
}

.brand-logo-container {
    position: relative;
}

.brand-logo-img {
    width: 50%;
    background-color: whitesmoke;
    margin: 5px;
    padding: 10px;
}

.brand-logo-cross-img {
    position: absolute;
    height: 1.5rem;
    right: 15.5rem;
    top: 2.5rem;
    cursor: pointer;
}

/*
* DEV CHANGES
*/

header .cont>ul>li button {
    font-size: 0.7rem;
}

header .icon-img-bel {
    height: 1.1 rem;
}

header img[alt="profile"] {
    height: 2.0rem
}

/* header .cont>ul>li strong {
    font-size: 0.7rem;
} */

header .cont>.realMenu>ul>li strong {
    font-size: 0.7rem;
}

header .icon-img {
    height: 0.8 rem;
}

header .cont>ul>li h3, header .cont>ul>li button {
    font-size: 0.9rem;
}

header img[alt="logo"] {
    height: 2.1rem;
}

header .cont {
    padding: 0.5rem 4.0rem 0.5rem 4.5rem;
}

footer .cont {
    height: 8rem;
}

footer img {
    max-width: 12rem;
}

main.cont {
    padding: 0 5 rem;
}

.chat .message_msgMake textarea {
    background: transparent;
    border: none;
    flex: 1 1 auto;
    resize: none;
}

.scroll::-webkit-scrollbar {
    display: none;
}

textarea {
    outline-color: #719ECE;
}

[data-letters]:before {
    content: attr(data-letters);
    display: inline-block;
    font-size: 1em;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    border-radius: 50%;
    background: plum;
    vertical-align: middle;
    color: white;
}

.chat {
    padding: 54px 0 0 !important;
}

.Dropdown-control {
    background-color: transparent !important;
    text-align: center;
    color: white !important;
    border-color: transparent !important;
    border-radius: 0.3rem !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    cursor: pointer;
}

.drop_down_menu {
    width: 100% !important;
    /* margin-left: 5px !important; */
    /* margin-top: 2px !important; */
    /* border-right: 1px !important; */
    /* border-left: 1px !important; */
}

.new_class {
    border-color: transparent !important;
    border-radius: 0.3rem !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    cursor: pointer;
}

.Dropdown-arrow {
    border-color: #fff transparent transparent !important
}

.dropdown-option-color-not-started {
    background-color: #d81b60;
    color: white !important;
}

.dropdown-option-color-not-started:hover {
    background-color: #d81b60 !important;
    color: white !important;
}

.dropdown-option-color-draft {
    background-color: #bbdefb;
    color: white !important;
}

.dropdown-option-color-draft:hover {
    background-color: #bbdefb !important;
    color: white !important;
}

.dropdown-option-color-review {
    background-color: #8e24aa;
    color: white !important;

}

.dropdown-option-color-review:hover {
    background-color: #8e24aa !important;
    color: white !important;

}

.dropdown-option-color-complete {
    background-color: #43a047;
    color: white !important;
}

.dropdown-option-color-complete:hover {
    background-color: #43a047 !important;
    color: white !important;
}

.dropdown-option-color-hold {
    background-color: #ffcc80;
    color: white !important;
}

.dropdown-option-color-hold:hover {
    background-color: #ffcc80 !important;
    color: white !important;
}

.dropdown-option-color-revision {
    background-color: #1e88e5;
    color: white !important;
}

.dropdown-option-color-revision:hover {
    background-color: #1e88e5 !important;
    color: white !important;
}
